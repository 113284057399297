import * as React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

export const Seo = ({ title, description, lang }) => {
  const queryData = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          description
          headline
          ogLanguage
          pathPrefix
          siteLanguage
          titleAlt
          banner
          author
        }
      }
    }
  `)

  const metaTitle = queryData.site?.siteMetadata?.title + ' || ' + title || ''

  const titleAlt = queryData.site?.siteMetadata?.titleAlt

  const author = queryData.site?.siteMetadata?.author

  const headline = queryData.site?.siteMetadata?.headline

  const url = queryData.site?.siteMetadata?.url

  const banner = queryData.site?.siteMetadata?.banner

  const metaDescription =
    description || queryData.site?.siteMetadata?.description

  return (
    <Helmet defer={false}>
      <title>{metaTitle}</title>
      <html lang={lang} />
      <meta name="description" content={metaDescription} />
      <meta charSet="utf-8" />
      <meta httpEquiv="x-ua-compatible" content="ie=edge" />
      <meta name="author" content={author} />
      <meta
        name="keywords"
        content="Educators, Professional Development, Professional Engineering Educator"
      />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={titleAlt} />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={banner} />
      <meta property="og:image:type" content="image/jpeg" />
      <meta property="og:image:alt" content="Smiling man with a book" />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:determiner" content="the" />
      <meta property="og:locale" content={lang} />
      {/* <meta property="og:locale:alternate" content="fr_FR" />
      <meta property="og:locale:alternate" content="es_ES" /> */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@enterprof" />
      <meta name="twitter:creator" content={author} />
      <meta name="twitter:title" content={titleAlt} />
      <meta name="twitter:image" content={banner} />
      <meta name="twitter:description" content={metaDescription} />
      {/* <meta
        property="og:video"
        content="https://example.com/bond/trailer.swf"
      /> */}
    </Helmet>
  )
}
