import * as React from 'react'
import { linkResolver } from '../../utils/LinkResolver'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { Row, Container, Col } from 'react-bootstrap'
import { StaticImage } from 'gatsby-plugin-image'
import { defaultLanguage } from '../../../prismic-configuration'

import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

export const Footer = ({ activeDocMeta }) => {
  const queryData = useStaticQuery(graphql`
    query prismicFooter {
      allPrismicFooterVariables {
        nodes {
          lang
          data {
            all_rights_reserved
            copyright___2020__
            developed_by
            development
            disclaimer
            educators
            email
            enter
            our_contacts_
            privacy_policy
            professional
          }
        }
      }
    }
  `)
  const { lang } = activeDocMeta

  const filterFooterLanguage = queryData.allPrismicFooterVariables.nodes.filter(
    (langVer) => langVer.lang === lang,
  )

  const footerVariables = filterFooterLanguage[0]?.data
  const localLinkResolver = lang === defaultLanguage ? '' : `/${lang}`

  return (
    <footer className="footer">
      <Container>
        <Row className="footer-row">
          <Col className="footer-flex" md={4}>
            <span className="back-to-top">
              <span
                onClick={() => {
                  document.body.scrollTop = 0
                  document.documentElement.scrollTop = 0
                }}
              >
                <i className="fa fa-angle-up fa" aria-hidden="true" />
              </span>
            </span>
            <Link to={`${localLinkResolver}/`}>
              <div className="footer-logo">
                <StaticImage
                  src="../../images/static-logo.png"
                  alt="logo"
                  width={80}
                  height={80}
                />
                <p>
                  {footerVariables.educators}
                  <br />
                  {footerVariables.professional}
                  <br />
                  {footerVariables.development}
                </p>
              </div>
            </Link>
            <div>
              <hr />
              <p className="footer__text">
                {footerVariables.all_rights_reserved} {'  '}
                <Link to={`${localLinkResolver}/`} rel="noopener noreferrer">
                  {footerVariables.enter}
                </Link>
                <br />
                <span>
                  {footerVariables.copyright___2020__}{' '}
                  {new Date().getFullYear()}
                </span>
              </p>
            </div>
          </Col>
          <Col className="footer-flex" md={4}></Col>

          <Col className="footer-flex" md={4}>
            <div className="footer__text">
              <div> {footerVariables.our_contacts_}</div>
              <a rel="noopener noreferrer" href="/" target="_blank">
                <i className="fa fa-envelope" aria-hidden="true"></i>
                {footerVariables.email}
              </a>
              <hr />
            </div>
            <div className="footer__text">
              <span>
                <Link to={`${localLinkResolver}/privacy`}>
                  {' '}
                  {footerVariables.privacy_policy}{' '}
                </Link>
              </span>
              {' || '}
              <span>
                <Link to={`${localLinkResolver}/disclaimer`}>
                  {' '}
                  {footerVariables.disclaimer}{' '}
                </Link>
              </span>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="footer__text" id="dev-by">
            {footerVariables.developed_by}{' '}
            <a
              href="https://www.linkedin.com/in/pavloomelianchuk/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {' '}
              Pavlo Omelianchuk
            </a>
          </div>
        </Row>
      </Container>
    </footer>
  )
}

export default withPrismicPreview(Footer, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
])
