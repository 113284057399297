import * as React from 'react'
import { navigate } from 'gatsby'

import { linkResolver } from '../utils/LinkResolver'

export const LanguageSwitcher = ({ activeDocMeta }) => {
  const currentLang = activeDocMeta.lang
  const currentLangOption = (
    <option value={currentLang}>{currentLang.slice(0, 2).toUpperCase()}</option>
  )

  const alternateLangOptions = activeDocMeta.alternateLanguages.map(
    (altLang, index) => (
      <option value={linkResolver(altLang)} key={`alt-lang-${index}`}>
        {altLang.lang.slice(0, 2).toUpperCase()}
      </option>
    ),
  )

  const handleLangChange = (event) => {
    navigate(event.target.value)
  }

  return (
    <div className="language-switcher">
      <select
        className="form-select form-select-sm m-1"
        aria-label=".form-select-sm"
        value={currentLang}
        onChange={handleLangChange}
      >
        {currentLangOption}
        {alternateLangOptions}
      </select>
    </div>
  )
}
