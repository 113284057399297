import React from 'react'
import { Link } from 'gatsby'

const NavLink = ({ children, to, loginBtnClass, navLinkClass }) => (
  <Link className={`${navLinkClass} ${loginBtnClass}`} to={to}>
    {children}
  </Link>
)

export default NavLink
